import React, { useMemo, useState } from "react";
import TextArea from "antd/lib/input/TextArea";
import Modal from "antd/lib/modal/Modal";
import { AudioFile, REJECTION_REASONS } from "../../api/db/repositories/audioFiles";
import { Form, FormInstance, Radio, RadioChangeEvent, Space } from "antd";

interface RejectTranscriptionModalProps {
  file: AudioFile | null;
  confirmLoading: boolean;
  onReject: () => void;
  onCancel: () => void;
  form: FormInstance;
}

const RejectTranscriptionModal = ({
  file,
  onReject,
  onCancel,
  confirmLoading,
  form,
}: RejectTranscriptionModalProps) => {
  const [rejectionReason, setRejectionReason] = useState<REJECTION_REASONS | "">("");
  const [rejectionMessage, setRejectionMessage] = useState<string>("");

  const resetInputs = () => {
    form.resetFields();
    setRejectionReason("");
    setRejectionMessage("");
  };

  const onChange = (e: RadioChangeEvent): void => {
    setRejectionReason(e.target.value);
  };

  const handleRejectionText = (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
    setRejectionMessage(e.target.value);
  };

  const { SPANISH, BAD_AUDIO, OTHER } = REJECTION_REASONS;
  const reasonToText = useMemo(
    () => ({
      [SPANISH]: "Spanish",
      [BAD_AUDIO]: "Bad Audio",
      [OTHER]: "Other",
    }),
    [BAD_AUDIO, SPANISH, OTHER]
  );

  return (
    <Modal
      visible={!!file}
      title={`Reject ${file?.filename}?`}
      okText="Reject"
      onCancel={onCancel}
      onOk={onReject}
      confirmLoading={confirmLoading}
      afterClose={resetInputs}
    >
      <Form form={form}>
        <Form.Item rules={[{ required: true, message: "Please enter a rejection reason" }]} name="rejectionReason">
          <Radio.Group onChange={onChange} value={rejectionReason}>
            <Space direction="vertical">
              <Radio value={SPANISH}>{reasonToText[SPANISH]}</Radio>
              <Radio value={BAD_AUDIO}>{reasonToText[BAD_AUDIO]}</Radio>
              <Radio value={OTHER}>{reasonToText[OTHER]}</Radio>
            </Space>
          </Radio.Group>
        </Form.Item>
        {rejectionReason === REJECTION_REASONS.OTHER && (
          <Form.Item name="rejectionMessage">
            <TextArea
              required
              rows={5}
              placeholder="Please enter the rejection reason..."
              value={rejectionMessage}
              onChange={handleRejectionText}
            ></TextArea>
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
};

export default RejectTranscriptionModal;
