import React, { useMemo, useState } from "react";
import { InboxOutlined } from "@ant-design/icons";
import { Button, Card, Divider, message, Table, Typography } from "antd";
import { UploadChangeParam } from "antd/lib/upload";
import Dragger from "antd/lib/upload/Dragger";
import { CompareData, compareGeckoFiles } from "../../api/pipeline/compareTranscripts";
import { showError } from "../../api/utils";
import { RcFile } from "antd/lib/upload/interface";

const CompareTranscripts = () => {
  const [fileList, setFileList] = useState<RcFile[]>([]);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [compareData, setCompareData] = useState<CompareData[] | null>(null);

  const handleBeforeUpload = (file: RcFile, allFiles: RcFile[]) => {
    const allowedFileTypes = ["application/json"];
    const isFileAllowed = allowedFileTypes.includes(file.type);

    if (!isFileAllowed) {
      message.error(`${file.name} is not a json file`);
    } else {
      setFileList(allFiles);
    }

    return false;
  };

  const handleChange = (info: UploadChangeParam) => {
    const { status } = info.file;
    if (status !== "uploading") {
    }
    if (status === "done") {
      message.success(`${info.file.name} file uploaded successfully.`);
    } else if (status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  const handleRemove = (file: RcFile) => {
    const index = fileList.indexOf(file);
    const fileListClone = [...fileList];
    fileListClone.splice(index, 1);
    setFileList(fileListClone);
  };

  const handleUpload = async () => {
    const formData = new FormData();
    fileList.forEach((file: unknown) => {
      formData.append("files[]", file as Blob);
    });
    setIsUploading(true);

    try {
      const { data } = await compareGeckoFiles(formData);
      if (data) {
        setCompareData(data);
      }
    } catch (e) {
      showError(e as Error);
    }
    setFileList([]);
    setIsUploading(false);
  };

  const isUploadDisabled = useMemo(() => fileList.length === 0 || fileList.length % 2 !== 0, [fileList.length]);

  const columns = [
    {
      title: "Group",
      dataIndex: "group",
      key: "group",
    },
    {
      title: "Substitutions",
      dataIndex: "substitutions",
      key: "substitutions",
    },
    {
      title: "Additions",
      dataIndex: "additions",
      key: "additions",
    },
    {
      title: "Deletions",
      dataIndex: "deletions",
      key: "deletions",
    },
    {
      title: "WER Score",
      dataIndex: "wer_score",
      key: "wer_score",
      render: (value: string) => value + "%",
    },
    {
      title: "Total Words",
      dataIndex: "total_words",
      key: "total_words",
    },
  ];

  return (
    <>
      <Card style={{ minHeight: "350px" }}>
        <Dragger
          name="transcriptFiles"
          multiple
          beforeUpload={handleBeforeUpload}
          onChange={handleChange}
          // @ts-ignore
          onRemove={handleRemove}
          // @ts-ignore
          fileList={fileList}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">Click or drag file to this area to upload</p>
          <p className="ant-upload-hint">Support for a single or bulk upload.</p>
          <p className="ant-upload-hint">Files should have the following naming convention:</p>
          <p className="ant-upload-hint">[filename]_pred.json and [filename]_gt.json</p>
          <p className="ant-upload-hint">For example:</p>
          <div style={{ textAlign: "left", width: "290px", margin: "0 auto" }}>
            <p className="ant-upload-hint">dunkin1_20211013-114932_pred.json</p>
            <p className="ant-upload-hint">dunkin1_20211013-114932_gt.json</p>
          </div>
          <p className="ant-upload-text">Files should always be uploaded in pairs</p>
        </Dragger>
        <Button
          type="primary"
          onClick={handleUpload}
          disabled={isUploadDisabled}
          loading={isUploading}
          style={{ marginTop: 16 }}
        >
          {isUploading ? "Uploading" : "Start Upload"}
        </Button>

        {fileList.length % 2 !== 0 && fileList.length !== 0 && (
          <>
            <Divider />
            <Typography.Text type="danger">
              Number of compared files must be 2, current file number: {fileList.length}
            </Typography.Text>
          </>
        )}
      </Card>

      {compareData && (
        <>
          <Divider />
          <Table dataSource={compareData} columns={columns} loading={isUploading} pagination={false} />
        </>
      )}
    </>
  );
};
export default CompareTranscripts;
