import React, { useEffect, useState } from "react";
import { RangeValue } from "rc-picker/lib/interface";
import { FilterOutlined } from "@ant-design/icons";
import { Divider } from "antd";
import Button from "antd/lib/button";
import DatePicker from "../DatePicker";
import { ServerSideFilters } from "../Pages/Files";

const { RangePicker } = DatePicker;

interface ExternalFilterProps {
  isLoading: boolean;
  onClick: (range: RangeValue<Date>) => void;
  onClear: () => void;
  dateRange: ServerSideFilters["creationDate"];
}

const DateRangeFilter = ({ isLoading, onClick, dateRange }: ExternalFilterProps) => {
  const [dateRangeFilter, setDateRangeFilter] = useState<RangeValue<Date>>(null);

  useEffect(() => {
    setDateRangeFilter(dateRange || null);
  }, [dateRange]);

  const handleDateChange = (range: RangeValue<Date>) => {
    setDateRangeFilter(range);
  };

  return (
    <>
      <Button
        type="primary"
        loading={isLoading}
        onClick={() => onClick(dateRangeFilter)}
        icon={<FilterOutlined />}
        size="middle"
        style={{ backgroundColor: "purple", border: "none", minWidth: "145px" }}
      >
        Filter by date
      </Button>
      <Divider type="vertical" />
      <RangePicker onChange={handleDateChange} value={dateRangeFilter} />
    </>
  );
};

export default DateRangeFilter;
