import React, { useEffect, useState } from "react";
import { DeleteTwoTone, PlusOutlined } from "@ant-design/icons";
import { Button, notification, Table, Typography, Modal, Divider } from "antd";
import userApi, { FirebaseSerializedUser, ROLES } from "../../api/users";
import { showError } from "../../api/utils";
import tagFactory from "../tagFactory";
import UserEditModal, { UserFormData } from "./UserEditModal";
import authentication from "../../api/authentication";
import { useAuth } from "../../contexts/Auth";
import { sendPasswordResetEmail } from "../../api/users/utils";

export enum ACTION {
  CREATE = "create",
  UPDATE = "update",
  DELETE = "delete",
}

const { confirm } = Modal;

const UserManagementTable = () => {
  const [editableItem, setEditableItem] = useState<FirebaseSerializedUser | null>(null);
  const [isEditModalVisible, setEditModalVisibility] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
  const [modifyableUsers, setModifyableUsers] = useState<FirebaseSerializedUser[]>([]);

  const { user } = useAuth();

  useEffect(() => {
    const fetchUsers = async () => {
      setLoading(true);
      const list = await userApi.listAll();
      setModifyableUsers(list.data);
      setLoading(false);
    };
    if (user) {
      fetchUsers();
    }
  }, [user]);

  const reconcileTableData = (
    action: ACTION,
    updateData: UserFormData | FirebaseSerializedUser | null,
    uid?: string
  ) => {
    let clone = [...modifyableUsers];
    const userIndex = clone.findIndex((oldUser) => oldUser.uid === uid);

    if (action === ACTION.UPDATE) {
      const reconciledUser = { ...clone[userIndex], ...updateData };
      clone.splice(userIndex, 1, reconciledUser as FirebaseSerializedUser);
    }
    if (action === ACTION.CREATE) {
      clone.push(updateData as FirebaseSerializedUser);
    }

    if (action === ACTION.DELETE) {
      clone = clone.filter((_, index) => index !== userIndex);
    }
    setModifyableUsers(clone);
  };

  const handleUserDeletion = (user: FirebaseSerializedUser) => () => {
    confirm({
      title: <Typography.Text type="danger">Delete {user.email}?</Typography.Text>,
      icon: <DeleteTwoTone twoToneColor="tomato" />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        userApi.remove(user.uid);
        notification["success"]({
          message: `User ${user.email} was deleted`,
        });
        reconcileTableData(ACTION.DELETE, null, user.uid);
      },
    });
  };

  const handleResetPassword = async () => {
    setConfirmLoading(true);
    await sendPasswordResetEmail(editableItem!.email);
    setConfirmLoading(false);
  };
  const handleCancel = () => setEditModalVisibility(false);

  const handleSubmit = async (action: ACTION, formData: UserFormData, uid?: string) => {
    try {
      setConfirmLoading(true);
      if (action === ACTION.UPDATE) {
        await userApi.update(uid!, formData);
        reconcileTableData(ACTION.UPDATE, formData, uid);
      }

      if (action === ACTION.CREATE) {
        const { data } = await userApi.create(formData as FirebaseSerializedUser);
        await authentication.sendPasswordResetEmail(formData.email!);
        reconcileTableData(ACTION.CREATE, {
          ...formData,
          uid: data.uid,
        } as FirebaseSerializedUser);
      }
      setConfirmLoading(false);
      setEditModalVisibility(false);
      notification["success"]({
        message: `User ${formData.email}`,
        description: `Successfully ${
          action === ACTION.UPDATE ? "updated" : "created and an email was sent to the user"
        }`,
      });
    } catch (e) {
      setConfirmLoading(false);
      showError(e as Error);
    }
  };

  const columns = [
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Name",
      dataIndex: "displayName",
      key: "displayName",
    },
    {
      title: "Roles",
      dataIndex: "roles",
      key: "roles",
      render: (roles: ROLES[]) => roles.map(tagFactory),
    },
    {
      title: "operation",
      dataIndex: "operation",
      render: (_: any, item: FirebaseSerializedUser) => {
        return (
          <>
            <Typography.Link
              onClick={() => {
                setEditableItem(item);
                setEditModalVisibility(true);
              }}
            >
              Edit
            </Typography.Link>
            <Divider type="vertical" />
            <Typography.Link type="danger" onClick={handleUserDeletion(item)}>
              Delete
            </Typography.Link>
          </>
        );
      },
    },
  ];

  return (
    <>
      <UserEditModal
        isVisible={isEditModalVisible}
        user={editableItem!}
        onSubmit={handleSubmit}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        onResetPassword={handleResetPassword}
      />
      <Button
        style={{ float: "right", marginBottom: "10px" }}
        icon={<PlusOutlined />}
        type="primary"
        onClick={() => {
          setEditableItem(null);
          setEditModalVisibility(true);
        }}
      >
        Add user
      </Button>
      <Table dataSource={modifyableUsers} rowKey="uid" columns={columns} loading={loading}></Table>
    </>
  );
};

export default UserManagementTable;
