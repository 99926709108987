import { Form, Input, Modal, Select, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { FirebaseSerializedUser, ROLES } from "../../api/users";
import { ACTION } from "./UserTable";

export interface UserFormData {
  displayName: string;
  roles: ROLES[];
  email?: string;
}

interface UserModalProps {
  isVisible: boolean;
  confirmLoading: boolean;
  user: FirebaseSerializedUser | null;
  onCancel: () => void;
  onSubmit: (action: ACTION, formData: UserFormData, uid?: string) => void;
  onResetPassword: () => void;
}

const { Option } = Select;

// @ts-ignore
const capitalizeFirstLetter = ([first, ...rest]: string) => {
  return first.toUpperCase() + rest.join("");
};

const layout = {
  labelCol: { span: 3 },
  wrapperCol: { span: 14 },
};

const UserEditModal = ({
  isVisible,
  confirmLoading,
  user,
  onCancel,
  onSubmit,
  onResetPassword,
}: UserModalProps) => {
  const [form] = Form.useForm();

  const [hasFieldstouched, setHasFieldsTouched] = useState<boolean>(false);

  const handleFieldChange = () => {
    setHasFieldsTouched(form.isFieldsTouched());
  };

  useEffect(() => {
    form.resetFields();
    if (user) {
      form.setFields([
        {
          name: "displayName",
          value: user?.displayName,
        },
        {
          name: "email",
          value: user?.email,
        },
        {
          name: "roles",
          value: user?.roles,
        },
      ]);
    }
  }, [user, form]);

  return (
    <Modal
      visible={isVisible}
      confirmLoading={confirmLoading}
      onCancel={onCancel}
      title={`${
        user?.uid
          ? capitalizeFirstLetter(ACTION.UPDATE)
          : capitalizeFirstLetter(ACTION.CREATE)
      } User  ${user?.email ?? ""}`}
      onOk={() => {
        const action = user?.uid ? ACTION.UPDATE : ACTION.CREATE;
        form.validateFields().then((values) => {
          onSubmit(action, values as UserFormData, user?.uid);
        });
      }}
      okButtonProps={{
        disabled: !hasFieldstouched,
      }}
      okText={
        user?.uid
          ? capitalizeFirstLetter(ACTION.UPDATE)
          : capitalizeFirstLetter(ACTION.CREATE)
      }
    >
      <Form form={form} {...layout}>
        <Form.Item
          name="displayName"
          label="Name"
          rules={[{ required: true, message: "Please input a name!" }]}
        >
          <Input
            type="text"
            onChange={handleFieldChange}
            placeholder="Input name"
          />
        </Form.Item>
        <Form.Item
          name="email"
          hidden={!!user}
          label="Email"
          rules={[{ required: true, message: "Please input valid email" }]}
        >
          <Input
            type="email"
            onChange={handleFieldChange}
            placeholder="Input email"
          />
        </Form.Item>

        <Form.Item
          name="roles"
          label="Roles"
          rules={[
            { required: true, message: "Please select at least one role" },
          ]}
        >
          <Select
            placeholder="Select at least one role"
            allowClear
            showSearch
            mode="multiple"
            onChange={handleFieldChange}
          >
            {Object.values(ROLES).map((role) => (
              <Option key={role} value={role}>
                {role.toUpperCase()}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
      {!!user && (
        <Typography.Link type="danger" onClick={onResetPassword}>
          Reset Password
        </Typography.Link>
      )}
    </Modal>
  );
};

export default UserEditModal;
