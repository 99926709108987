import { notification } from "antd";
import authentication from "../authentication";
import { showError } from "../utils";

export const sendPasswordResetEmail = async (email: string) => {
   return  authentication
      .sendPasswordResetEmail(email)
      .then(() => {
        notification["success"]({
          message: "Password Reset",
          description: "Password reset successfully sent to " + email,
        });
      })
      .catch(showError);
};
