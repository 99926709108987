import axios from "axios";
import firebase from "firebase";
import authentication from "../authentication";
import { parseToken } from "../utils";

const { REACT_APP_DOROTHY_PIPELINE_URL } = process.env;
export const pipeline = axios.create({
  baseURL: `${REACT_APP_DOROTHY_PIPELINE_URL}`,
});

authentication.onIdTokenChanged(async (user: firebase.User | null) => {
  const idToken = await user?.getIdToken();
  pipeline.defaults.headers.common.Authorization = parseToken(idToken);
});
