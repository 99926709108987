import { notification } from "antd";

export const showError = (e: Error) => {
  console.error(e);
  const code = (e as any).code ? ": " + (e as any).code : "";
  notification["error"]({
    message: `Error ${code}`,
    description: e.message,
  });
};

export const parseToken = (token?: string) => (token ? `Bearer ${token}` : "");
