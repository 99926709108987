import React, { useState } from "react";
import { RangeValue } from "rc-picker/lib/interface";
import { DownloadOutlined } from "@ant-design/icons";
import { Button, ButtonProps, Divider, notification } from "antd";
import { ExportToCsv } from "export-to-csv";
import { audioFiles } from "../../api/db/repositories/audioFiles";
import { getSubmissionDateTimestamp, getSubmissionDateMiliSeconds } from '../utils';
import DatePicker from "../DatePicker";

interface CSVExporterProps {
  exportButtonProps?: ButtonProps;
}

interface CSVAudioFile {
  file: string;
  brand: string;
  branch: string;
  status: string;
  transcriber: string;
  reviewer: string;
  qcReviewer: string;
  duration: number | string;
  "transcriber submission date": string | Date;
  "reviewer submission date": string | Date;
  "qcReviewer submission date": string | Date;
  "last update": string | Date;
  tags: string;
  "revision number": number | string;
}

const { RangePicker } = DatePicker;



const CSVExporter = ({ exportButtonProps }: CSVExporterProps) => {
  const [exportDateRange, setExportDateRange] = useState<RangeValue<Date>>(null);
  const [isLoading, setLoading] = useState<boolean>(false);

  const exportCSV = async () => {
    setLoading(true);
    const data = await audioFiles.getDataForCSV(exportDateRange);
    
    const options = {
      useKeysAsHeaders: true,
    };

    const csvExporter = new ExportToCsv(options);

    let flatCSVData: CSVAudioFile[] = [];
    data.forEach(
      ({ filename, status, transcriber, reviewer, duration, revisionDuration, submissionDates, creationDate, tags = {}, revisions, qcReviewer }) => {
        const filenameData = filename.split('/');
        const brand = filenameData[0].replace(/[0-9]/g, '');
        const branch = filenameData[0];
        const shortFilename = filenameData[filenameData.length -1];
        const submissionDateTranscriber = getSubmissionDateTimestamp(submissionDates?.transcriber);
        const submissionDateReviewer = getSubmissionDateTimestamp(submissionDates?.reviewer);
        const submissionDateQcReviewer = getSubmissionDateTimestamp(submissionDates?.qcReviewer);        
        const lastUpdateValue = Math.max(
          getSubmissionDateMiliSeconds(submissionDateTranscriber),
          getSubmissionDateMiliSeconds(submissionDateReviewer),
          getSubmissionDateMiliSeconds(submissionDateQcReviewer)
        );
        const lastUpdate = lastUpdateValue === 0 ? getSubmissionDateTimestamp(creationDate) : new Date(lastUpdateValue);
        let results: CSVAudioFile[] = [];
        const rootFileData: CSVAudioFile = {
          "revision number": "",
          brand,
          branch,
          file: shortFilename,
          status,
          transcriber: transcriber?.email ?? "",
          reviewer: reviewer?.email ?? "",
          qcReviewer: qcReviewer?.email ?? "",
          duration: revisionDuration || duration || "",
          "transcriber submission date": submissionDateTranscriber ?? "",
          "reviewer submission date": submissionDateReviewer ?? "",
          "qcReviewer submission date": submissionDateQcReviewer ?? "",
          "last update": lastUpdate ?? "",
          tags: Object.keys(tags).join(","),
        };

        if (revisions?.length) {
          rootFileData["revision number"] = revisions.length;
          revisions.forEach((revision, index) => {
            const revisionCSVData: CSVAudioFile = {
              "revision number": index,
              brand,
              branch,
              file: shortFilename,
              status,
              transcriber: transcriber?.email ?? "",
              reviewer: revision.reviewer?.email,
              qcReviewer: qcReviewer?.email ?? "",
              duration: revision.duration || "",
              "transcriber submission date": submissionDateTranscriber ?? "",
              "reviewer submission date": submissionDateReviewer ?? "",
              "qcReviewer submission date": submissionDateQcReviewer ?? "",
              "last update": lastUpdate ?? "",
              tags: Object.keys(tags).join(","),
            };
            results.push(revisionCSVData);
          });
        }

        results.push(rootFileData);
        flatCSVData = [...flatCSVData, ...results];
      }
    );

    if (flatCSVData.length) {
      csvExporter.generateCsv(flatCSVData);
    } else {
      notification["warning"]({
        message: "No items exist in the selected range",
      });
    }
    setLoading(false);
  };

  const handleDateChange = (range: RangeValue<Date>) => {
    setExportDateRange(range);
  };

  return (
    <>
      <Button
        type="primary"
        loading={isLoading}
        onClick={exportCSV}
        icon={<DownloadOutlined />}
        size="middle"
        {...exportButtonProps}
      >
        Export to CSV
      </Button>
      <Divider type="vertical" />
      <RangePicker onChange={handleDateChange} />
    </>
  );
};

export default CSVExporter;
