import React from "react";
import { Navigate, Outlet, RouteProps, useLocation } from "react-router-dom";
import { ROLES } from "../api/users";
import { useAuth } from "../contexts/Auth";

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
function PrivateRoute({ allowedRoles, ...rest }: RouteProps & { allowedRoles: ROLES[] }) {
  let { user, roles } = useAuth();
  const location = useLocation();
  return !!user && roles.some((role) => allowedRoles.includes(role)) ? (
    <Outlet />
  ) : (
    <Navigate to={{ pathname: "/login" }} state={{ from: location }} />
  );
}

export default PrivateRoute;
