import React from "react";
import { Tag, Typography } from "antd";
import { RangeValue } from "rc-picker/lib/interface";

interface FilterTagsProps {
  dateRange: RangeValue<Date>;
  onDateTagClose: () => void;
}
const FilterTags = ({ dateRange, onDateTagClose }: FilterTagsProps) => {
  const [startDate, endDate] = dateRange as Date[];

  return (
    <Typography.Paragraph>
      <Tag closable onClose={onDateTagClose} color="processing">
        <Typography.Text strong style={{ color: "inherit" }}>
          {startDate!.toLocaleDateString()}-{endDate!.toLocaleDateString()}
        </Typography.Text>
      </Tag>
    </Typography.Paragraph>
  );
};

export default FilterTags;
