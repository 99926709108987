import { AxiosResponse } from "axios";
import { pipeline } from "./";

export interface CompareData {
  group: string;
  substitutions: number;
  additions: number;
  deletions: number;
  wer_score: number;
  total_words: number;
}

const { REACT_APP_DOROTHY_PIPELINE_COMPARE_TOKEN } = process.env;
const compareGeckoFiles = async (fileList: FormData): Promise<AxiosResponse<CompareData[]>> =>
  pipeline.post(`/compare_gecko_wer_and_gt?code=${REACT_APP_DOROTHY_PIPELINE_COMPARE_TOKEN}`, fileList);

export { compareGeckoFiles };
