import { Tag } from "antd";
import React from "react";
import { AUDIOFILE_STATUS, PRIORITY } from "../api/db/repositories/audioFiles";
import { ROLES } from "../api/users";

const tagFactory = (status: Partial<PRIORITY | AUDIOFILE_STATUS | ROLES>) => {
  const {
    NEW,
    TRANSCRIBE_IN_PROGRESS,
    WAITING_FOR_REVIEW,
    REVIEW_IN_PROGRESS,
    REVIEWED,
    REJECTED_BY_TRANSCRIBER,
    WAITING_FOR_ERROR_CORRECTION,
    ERROR_CORRECTION_IN_PROGRESS,
    QC_IN_PROGRESS,
    PASSED_QC
  } = AUDIOFILE_STATUS;
  const { LOW, MEDIUM, HIGH } = PRIORITY;
  const { ADMIN, REVIEWER, TRANSCRIBER, VIEWER, QC_REVIEWER } = ROLES;

  const colorMap = {
    [NEW]: "geekblue",
    [TRANSCRIBE_IN_PROGRESS]: "purple",
    [WAITING_FOR_REVIEW]: "aqua",
    [REVIEW_IN_PROGRESS]: "magenta",
    [WAITING_FOR_ERROR_CORRECTION]: "#56240c",
    [ERROR_CORRECTION_IN_PROGRESS]: "volcano",
    [REVIEWED]: "green",
    [LOW]: "yellow",
    [MEDIUM]: "orange",
    [HIGH]: "red",
    [ADMIN]: "green",
    [TRANSCRIBER]: "yellow",
    [REVIEWER]: "purple",
    [QC_REVIEWER]: "brown",
    [QC_IN_PROGRESS]: "turquoise",
    [PASSED_QC]: "dodgerblue",
    [VIEWER]: "grey",
    [REJECTED_BY_TRANSCRIBER]: "volcano",
  };

  return (
    <Tag key={status} color={colorMap[status]}>
      {status?.toUpperCase()}
    </Tag>
  );
};

export default tagFactory;
