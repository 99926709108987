import { AudioFile, AUDIOFILE_STATUS } from "../db/repositories/audioFiles";
import { ROLES } from "../users";
import blobService from "./blobService";

export const ANNOTATION_FILE_EXTENSION = "json";

const buildStatusFileName = (filename: string, status: AUDIOFILE_STATUS) => {
  const { REACT_APP_AZURE_BLOB_STATUS_FOLDER } = process.env;
  const [name, extension] = filename.split(".");
  const uid = new Date().getTime();
  const uniqueName = `${name}_${uid}.${extension}`;
  const restructuredPath = [REACT_APP_AZURE_BLOB_STATUS_FOLDER, status, uniqueName].join("/");
  return restructuredPath;
};

export const generateGeckoFileURL = (
  { filename: name, status, transcriptionSuggestion }: AudioFile,
  roles: ROLES[]
) => {
  const { REACT_APP_AZURE_BLOB_PATH: blobPath } = process.env;
  const { WAITING_FOR_ERROR_CORRECTION, ERROR_CORRECTION_IN_PROGRESS } = AUDIOFILE_STATUS;
  const [filename] = name.split(".");
  const parsedName = generatePrefixedName(filename);

  let url = `/gecko/?save_mode=server&audio=${blobPath}/${parsedName}.wav&json=${blobPath}/${parsedName}.json`;
  const isErrorCorrectionFlow = [WAITING_FOR_ERROR_CORRECTION, ERROR_CORRECTION_IN_PROGRESS].some((s) => s === status);

  if (isErrorCorrectionFlow) {
    url += `,${blobPath}/${transcriptionSuggestion}&comparisonMode=true`;
  }

  if (roles.includes(ROLES.VIEWER)) {
    url += "&readOnly=true";
  }

  return url;
};

export const generatePrefixedName = (name: string) => {
  const { REACT_APP_AZURE_BLOB_PREFIX: prefix } = process.env;
  let parsedName = name;

  if (prefix) {
    parsedName = `${prefix}/${name}`;
  }
  return parsedName;
};

const updateAnnotationFilePath = async (name: string, status: AUDIOFILE_STATUS) => {
  const filename = generatePrefixedName(name);
  const source = blobService.containerClient.getBlobClient(`${filename}.${ANNOTATION_FILE_EXTENSION}`);

  const destination = blobService.containerClient.getBlobClient(buildStatusFileName(source.name, status));

  const response = await destination.beginCopyFromURL(source.url);
  return response.pollUntilDone();
};

export default updateAnnotationFilePath;
