import React, { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}
const Gecko = () => {
  const [url, setUrl] = useState<string>();
  const query = useQuery().toString();

  const init = useCallback(async () => {
    const { REACT_APP_GECKO_BASE_URL } = process.env;
    const geckoUrl = REACT_APP_GECKO_BASE_URL + "/?" + decodeURIComponent(query);
    setUrl(geckoUrl);
  }, [query]);

  useEffect(() => {
    init();
  }, [init]);

  return <iframe className="gecko-iframe" title="gecko" src={url} />;
};

export default Gecko;
