import { Button, Card, Checkbox, Form, Input, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../../contexts/Auth";

interface LoginValues {
  email: string;
  password: string;
}

const Login = () => {
  const { login, user } = useAuth();
  const [isLoading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation();
  // @ts-ignore
  const { from = { pathname: "/" } } = location.state || {};

  const onFinish = async ({ email, password }: LoginValues) => {
    setLoading(true);
    await login(email, password);
    setLoading(false);
    navigate(from, { replace: true });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const layout = {
    labelCol: { span: 5 },
    wrapperCol: { span: 16 },
  };

  const tailLayout = {
    wrapperCol: { offset: 5, span: 16 },
  };

  useEffect(() => {
    if (user && !user.isAnonymous) {
      navigate(from, { replace: true });
    }
  }, [user, navigate, from]);

  return (
    <Row justify="center" align="middle" style={{ minHeight: "calc(100vh - 64px)" }}>
      <Card style={{ width: "720px" }} title="Login">
        <Form
          {...layout}
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item label="email" name="email" rules={[{ required: true, message: "Please input your username!" }]}>
            <Input type="email" />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: "Please input your password!" }]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item {...tailLayout} name="remember" valuePropName="checked">
            <Checkbox>Remember me</Checkbox>
          </Form.Item>

          <Form.Item {...tailLayout}>
            <Button type="primary" htmlType="submit" loading={isLoading}>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </Row>
  );
};

export default Login;
