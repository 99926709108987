import {Typography} from "antd";
import React from "react";
import UserManagementTable from "../UserManagement/UserTable";

const UserManagement = () => <>
  <Typography.Title level={2}> Manage Users </Typography.Title>
  <UserManagementTable/>

</>


export default UserManagement;
