import { pipeline } from ".";
import {AUDIOFILE_STATUS} from "../db/repositories/audioFiles";

interface Term {
  start: number;
  end: number;
  test: string;
  type: "WORD" | "PUNCTUATION";
}

enum MonologueErrorType {
  ERROR_TYPE_BAD_SPEAKER_ID = "bad_speaker_id",
  ERROR_TYPE_NULL_TIME_STAMP = "null_timestamp",
  ERROR_TYPE_MISSING_SPEAKER_ID = "missing_speaker",
  ERROR_TYPE_UNKNOWN_WORD = "unknown_word",
}

interface MonologueError {
  type: MonologueErrorType;
  severity: "warning" | "error" | "comment";
  text: string;
}

interface Monologue {
  start: number;
  end: number;
  speaker: { id: string };
  terms: Term[];
  errors?: MonologueError[];
}

interface VerificationAPIPayload {
  monologues: Monologue[];
  colors: Record<string, string>;
}

const { REACT_APP_DOROTHY_PIPELINE_VERIFICATION_TOKEN } = process.env;
const TRANSCRIPTION_VERIFICATION_PATH = `/transcription_verification?code=${REACT_APP_DOROTHY_PIPELINE_VERIFICATION_TOKEN}`;

export const getTranscriptionVerificationStatus = async (content: string) => {
  const { data: verifiedData } = await pipeline.post<VerificationAPIPayload>(TRANSCRIPTION_VERIFICATION_PATH, content);
  const severityPluralizer: Record<MonologueError["severity"], string> & Record<"default", "other"> = {
    error: "errors",
    warning: "warnings",
    comment: "comments",
    default: "other",
  };

  type DiscrepencyMapKeys = "warnings" | "errors" | "comments" | "other";
  type DiscrepencyMap = Record<DiscrepencyMapKeys, MonologueError[]>;
  const { monologues } = verifiedData;
  const discrepencyMap: DiscrepencyMap = monologues.reduce(
    (acc, monologue) => {
      if (monologue.errors?.length) {
        monologue.errors.forEach((discrepency) => {
          const key = (severityPluralizer[discrepency.severity] || severityPluralizer.default) as DiscrepencyMapKeys;
          acc[key] = [...acc[key], discrepency];
        });
      }

      return acc;
    },
    {
      warnings: [],
      errors: [],
      comments: [],
      other: [],
    } as DiscrepencyMap
  );
  return discrepencyMap;
};

export const postReviewedFile = async (prefixedFilename: string, data: string, status: AUDIOFILE_STATUS, tags: string[]) => {
  return pipeline.post(`${TRANSCRIPTION_VERIFICATION_PATH}&verified=true&filename=${prefixedFilename}&status=${status}&tags=${tags}`, data);
};
