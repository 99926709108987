import { BlobServiceClient } from "@azure/storage-blob";
import axios from "axios";

const getBlobServiceClient = () => {
  const { REACT_APP_AZURE_BLOB_ACCOUNT, REACT_APP_AZURE_SAS_TOKEN } = process.env;
  const url = `https://${REACT_APP_AZURE_BLOB_ACCOUNT}.blob.core.windows.net/${REACT_APP_AZURE_SAS_TOKEN}`;
  const blobServiceClient = new BlobServiceClient(url);
  return blobServiceClient;
};

const getContainerClient = (containerName: string) => {
  const blobServiceClient = getBlobServiceClient();
  const containerClient = blobServiceClient.getContainerClient(containerName);
  return containerClient;
};

const buildBlobURL = (filename: string) => {
  const {
    REACT_APP_AZURE_BLOB_ACCOUNT,
    REACT_APP_AZURE_SAS_TOKEN,
    REACT_APP_AZURE_STORAGE_CONTAINER_NAME,
  } = process.env;
  const url = `https://${REACT_APP_AZURE_BLOB_ACCOUNT}.blob.core.windows.net/${REACT_APP_AZURE_STORAGE_CONTAINER_NAME}/${filename}.json${REACT_APP_AZURE_SAS_TOKEN}`;

  return url;
};

const getAzureFileData = async (filename: string) => {
  const url = blobService.buildBlobURL(filename);
  const { data } = await axios.get(url);
  return data;
};

const blobService = {
  bloblServiceClient: getBlobServiceClient(),
  containerClient: getContainerClient(process.env.REACT_APP_AZURE_STORAGE_CONTAINER_NAME!),
  buildBlobURL,
  getAzureFileData,
};

export default blobService;
