import LogRocket from "logrocket";
import React, { ReactNode, useContext, useEffect, useState } from "react";
import authentication from "../api/authentication";
import firebase from "../api/firebase";
import { ROLES } from "../api/users";
import { showError } from "../api/utils";
import Cookies from "universal-cookie";

interface AuthContextProps {
  user: firebase.User | null;
  roles: ROLES[];
  login: (email: string, password: string) => Promise<firebase.auth.UserCredential | void>;
}

const login = async (email: string, password: string) =>
  authentication.signInWithEmailAndPassword(email, password).catch(showError);

const AuthContext = React.createContext<AuthContextProps>({
  user: null,
  roles: [],
  login,
});

const useAuth = () => useContext(AuthContext);

const setupIdentity = (user: firebase.User | null, roles: ROLES[]) => {
  const { uid = "N/A", email, displayName } = user ?? {};
  LogRocket.identify(uid, {
    email: email || "N/A",
    name: displayName || "N/A",
    roles: roles.join(),
  });
};

const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useState<firebase.User | null>(null);
  const [roles, setRoles] = useState<ROLES[]>([]);

  useEffect(() => {
    const unsubscribeAuthStateChange = authentication.onAuthStateChanged(async (user) => {
      const { claims } = (await user?.getIdTokenResult()) ?? {};
      const parsedRoles: ROLES[] = claims?.roles ?? [];

      setRoles(parsedRoles);
      setUser(user);
      setupIdentity(user, parsedRoles);
    });

    const unsubscribeIdTokenChange = authentication.onIdTokenChanged(async (user) => {
      const { REACT_APP_DOMAIN_SCOPE } = process.env;
      const cookies = new Cookies();
      const token = await user?.getIdToken();
      cookies.remove("hiAutoSlsToken");
      if (token) {
        cookies.set("hiAutoSlsToken", token, { domain: REACT_APP_DOMAIN_SCOPE });
      }
    });

    return () => [unsubscribeAuthStateChange, unsubscribeIdTokenChange].forEach((unsubscriber) => unsubscriber());
  }, []);

  const auth = {
    user,
    roles,
    login,
  };

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

export { AuthProvider, useAuth };
