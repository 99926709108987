import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { ROLES } from "./api/users";
import "./App.css";
import Page from "./components/Page";
import CompareTranscripts from "./components/Pages/CompareTranscripts";
import Files from "./components/Pages/Files";
import Gecko from "./components/Pages/Gecko";
import Login from "./components/Pages/Login";
import UserManagement from "./components/Pages/UserManagement";
import PrivateRoute from "./components/PrivateRoute";

function App() {
  return (
    <div className="app-container">
      <Routes>
        <Route path="/" element={<Navigate to="/files" />} />
        <Route element={<Page />}>
          <Route path="/login" element={<Login />} />
          <Route element={<PrivateRoute allowedRoles={[ROLES.ADMIN]} />}>
            <Route path="/users" element={<UserManagement />} />
            <Route path="/compareTranscripts" element={<CompareTranscripts />} />
          </Route>
          <Route element={<PrivateRoute allowedRoles={Object.values(ROLES)} />}>
            <Route path="/files" element={<Files />} />
          </Route>
        </Route>
        <Route element={<PrivateRoute allowedRoles={Object.values(ROLES)} />}>
          <Route path="/gecko" element={<Gecko />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
