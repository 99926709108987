import firebase from "firebase";
import authentication from "../authentication";
import axios from "axios";
import { parseToken } from "../utils";

export enum ROLES {
  VIEWER = "viewer",
  ADMIN = "admin",
  TRANSCRIBER = "transcriber",
  REVIEWER = "reviewer",
  QC_REVIEWER = "qc_reviewer"
}

export interface FirebaseSerializedUser {
  uid: string;
  displayName: string;
  email: string;
  //password: string;
  roles: ROLES[];
}

const { REACT_APP_DOROTHY_API_URL } = process.env;

const users = axios.create({
  baseURL: `${REACT_APP_DOROTHY_API_URL}/users`,
});

const listAll = () => users.get("/");
const create = (data: FirebaseSerializedUser) => {
  return users.post("/", data);
};

const find = (uid: string) => users.get(`/${uid}`);
const update = (uid: string, data: Partial<FirebaseSerializedUser>) => users.patch(`/${uid}`, data);
const remove = (uid: string) => users.delete(`/${uid}`);

authentication.onIdTokenChanged(async (user: firebase.User | null) => {
  const idToken = await user?.getIdToken();
  users.defaults.headers.common.Authorization = parseToken(idToken);
});
const userApi = { listAll, create, find, update, remove };
export default userApi;
