import db, { COLLECTIONS } from ".";

export interface FilePath {
  id: string;
  path: string;
}

const dedupePath = (paths: FilePath[]): FilePath[] => {
  const pathMap: Record<string, FilePath> = {};
  paths.forEach((filePath) => {
    if (!pathMap[filePath.path]) {
      pathMap[filePath.path] = filePath;
    }
  });

  return Object.values(pathMap);
};
const getAll = async (): Promise<FilePath[]> => {
  const snapshot = await db.collection(COLLECTIONS.PATHS).get();
  const paths = snapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  })) as FilePath[];

  // temporary client dedupe until server is indexed
  const dedupedPaths = dedupePath(paths);
  const filteredPaths = dedupedPaths.filter((p) => p.path.includes("enhanced_seg"));
  return filteredPaths.sort((a, b) => {
    if (a.path < b.path) {
      return -1;
    }
    if (a.path > b.path) {
      return 1;
    }
    return 0;
  });
};

export const filePaths = { getAll };
