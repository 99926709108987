import React, { useEffect, useState } from "react";
import { Button, Select } from "antd";
import { ClearOutlined } from "@ant-design/icons";

interface FilenameFilterProps {
  onClear: () => void;
  onClick: (filenames: string[]) => void;
  values?: string[] | null;
}

const FilenameFilter = ({ onClear, onClick, values }: FilenameFilterProps) => {
  const [filenames, setFilenames] = useState<string[]>(values || []);
  const handleChange = (values: string[]) => {
    const parsedValues = values.map((v) => v.trim()).filter(Boolean);
    setFilenames(parsedValues);
  };

  const handleClear = () => {
    setFilenames([]);
    onClear();
  };

  useEffect(() => {
    setFilenames(values || []);
  }, [values]);
  return (
    <div style={{ display: "inline-flex", width: "100%", margin: "10px 0" }}>
      <Select
        mode="tags"
        style={{ width: "100%" }}
        tokenSeparators={[","]}
        dropdownStyle={{ display: "none" }}
        onChange={handleChange}
        placeholder="Enter comma separated file names, e.g lees1/enhanced_seg/lees1_20210310-144502.wav"
        allowClear
        onClear={handleClear}
        value={filenames}
      />
      <Button type="primary" onClick={() => onClick(filenames)}>
        Search
      </Button>
      <Button
        icon={<ClearOutlined />}
        style={{ visibility: !!filenames.length ? "visible" : "hidden" }}
        onClick={handleClear}
      >
        Clear
      </Button>
    </div>
  );
};

export default FilenameFilter;
