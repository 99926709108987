import { Layout, Menu, notification } from "antd";
import { useMemo, useState } from "react";
import { NavLink, Outlet } from "react-router-dom";
import { useAuth } from "../contexts/Auth";
import authentication from "../api/authentication";
import { showError } from "../api/utils";
import { FileOutlined, SettingOutlined, UserOutlined } from "@ant-design/icons";
import { ROLES } from "../api/users";
const { Header, Content, Sider } = Layout;
const { SubMenu } = Menu;

const Page = () => {
  const [isSiderCollapsed, setSliderCollapse] = useState<boolean>(false);
  const { user, roles } = useAuth();

  const handleSignout = async () => {
    await authentication.signOut().catch(showError);
  };

  const onCollapse = (collapsed: boolean) => {
    setSliderCollapse(collapsed);
  };

  const handlePasswordReset = async () => {
    if (user?.email) {
      authentication
        .sendPasswordResetEmail(user.email)
        .then(() => {
          notification["success"]({
            message: "Password Reset",
            description: "Password reset successfully sent to " + user?.email,
          });
        })
        .catch(showError);
    }
  };

  const isAdmin = useMemo(() => {
    return user && roles.includes(ROLES.ADMIN);
  }, [user, roles]);

  return (
    <Layout className="main-layout" style={{ minHeight: "100vh" }}>
      <Sider collapsible collapsed={isSiderCollapsed} onCollapse={onCollapse}>
        <div className="logo">Dorothy Web </div>
        <Menu theme="dark" defaultSelectedKeys={["1"]} mode="inline" defaultOpenKeys={["admin"]}>
          {user && (
            <SubMenu key="sub1" icon={<UserOutlined />} title={user.email}>
              <Menu.Item key="signOut" onClick={handleSignout}>
                Sign out
              </Menu.Item>
              <Menu.Item key="resetPassword" onClick={handlePasswordReset}>
                Reset password
              </Menu.Item>
            </SubMenu>
          )}
          {user && (
            <Menu.Item key="files" icon={<FileOutlined />}>
              <NavLink to="/files">Files</NavLink>
            </Menu.Item>
          )}
          {isAdmin && (
            <SubMenu icon={<SettingOutlined />} title="Admin" key="admin">
              <Menu.Item key="manageUsers">
                <NavLink to="/users"> Manage Users</NavLink>
              </Menu.Item>
              <Menu.Item key="compareTranscripts">
                <NavLink to="/compareTranscripts">Compare Transcripts</NavLink>
              </Menu.Item>
            </SubMenu>
          )}
        </Menu>
      </Sider>

      <Layout>
        <Header />
        <Content className="main-content">
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};

export default Page;
