import db, { COLLECTIONS } from ".";

export interface FileTag {
  name: string;
}
const getAll = async () => {
  const snapshot = await db.collection(COLLECTIONS.TAGS).get();
  return snapshot.docs.map((doc) => doc.data()) as FileTag[];
};

export const fileTags = { getAll };
