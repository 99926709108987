import firebase from "../../firebase";
import { FileChangePayload, ServerSideFilters } from "../../../components/Pages/Files";
import { FirebaseSerializedUser, ROLES } from "../../users";
import db, { COLLECTIONS } from "./";
import { chunk } from "lodash/fp";
import { RangeValue } from "rc-picker/lib/interface";

const MINIMUM_FILE_DURATION = 5;

export const PAGE_SIZE = 15;
export enum AUDIOFILE_STATUS {
  NEW = "new",
  TRANSCRIBE_IN_PROGRESS = "transcribe_in_progress",
  WAITING_FOR_REVIEW = "waiting_for_review",
  REVIEW_IN_PROGRESS = "review_in_progress",
  WAITING_FOR_ERROR_CORRECTION = "waiting_for_error_correction",
  ERROR_CORRECTION_IN_PROGRESS = "error_correction_in_progress",
  REVIEWED = "reviewed",
  QC_IN_PROGRESS = "qc_in_progress",
  PASSED_QC = "passed_qc",
  REJECTED_BY_TRANSCRIBER = "rejected_by_transcriber",
}

export enum PRIORITY {
  HIGH = "high",
  MEDIUM = "medium",
  LOW = "low",
}

export enum REJECTION_REASONS {
  SPANISH = "spanish",
  BAD_AUDIO = "bad_audio",
  OTHER = "other",
}

export type ModifiacationDate = firebase.firestore.Timestamp; 
export interface SubmissionDates {
  reviewer?: ModifiacationDate;
  transcriber?: ModifiacationDate;
  qcReviewer?: ModifiacationDate;
}

export interface RejectionLog {
  reason: REJECTION_REASONS;
  message?: string;
  rejectedBy: AudioFile["transcriber"] | (AudioFile["reviewer"] & { roles: ROLES[] });
  date: Date;
}

export type UserLog = Pick<FirebaseSerializedUser, "email" | "displayName">;

export interface Revision {
  reviewer: UserLog;
  transcriber?: UserLog;
  submissionDates: SubmissionDates;
  duration?: number;
}

export interface AudioFile {
  id: string;
  filename: string;
  status: AUDIOFILE_STATUS;
  priority: PRIORITY;
  creationDate: ModifiacationDate;
  duration: number;
  transcriber?: UserLog;
  reviewer?: UserLog;
  qcReviewer?: UserLog;
  submissionDates?: SubmissionDates;
  path: string;
  tags: Record<string, true>;
  rejectionLogs?: RejectionLog[];
  revisions?: Revision[];
  transcriptionSuggestion?: string;
  revisionDuration?: number;
}

export type FirebaseDocumentData = firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>;
export type FirestoreChangeSubscriptionCallback = (file: FirebaseDocumentData, source: "local" | "server") => void;

const subscribeToFileChanges = (snapshot: firebase.firestore.Query, callback: FirestoreChangeSubscriptionCallback) => {
  return snapshot.onSnapshot((snapshot) => {
    snapshot.docChanges().forEach((change) => {
      const source = change.doc.metadata.hasPendingWrites ? "local" : "server";
      if (change.type === "modified") {
        const { doc } = change;
        callback(doc, source);
      }
    });
  });
};

const mergeCSVData = (
  transcriberDocs: firebase.firestore.DocumentData[],
  reviewerDocs: firebase.firestore.DocumentData[],
  qcReviewerDocs: firebase.firestore.DocumentData[],
) => {
  const docMap: Record<string, firebase.firestore.DocumentData> = {};

  transcriberDocs.forEach((doc) => {
    if (!docMap[doc.id]) {
      docMap[doc.id] = doc.data();
    }
  });

  reviewerDocs.forEach((doc) => {
    if (!docMap[doc.id]) {
      docMap[doc.id] = doc.data();
    }
  });

  qcReviewerDocs.forEach((doc) => {
    if (!docMap[doc.id]) {
      docMap[doc.id] = doc.data();
    }
  });
  return Object.values(docMap);
};

const getDataForCSV = async (exportDateRange?: RangeValue<Date>) => {
  if (exportDateRange) {
    const [start, end] = exportDateRange;
    // Calling overlapping data twice and reconciling
    // This is to bypass the lack of logical or (union) in firestore
    const transcriberSnapshot = db
      .collection(COLLECTIONS.AUDIO_FILES)
      .where("submissionDates.transcriber", ">=", start)
      .where("submissionDates.transcriber", "<=", end);

    const reviewerSnapshot = db
      .collection(COLLECTIONS.AUDIO_FILES)
      .where("submissionDates.reviewer", ">=", start)
      .where("submissionDates.reviewer", "<=", end);

    const qcReviewerSnapshot = db
      .collection(COLLECTIONS.AUDIO_FILES)
      .where("submissionDates.qcReviewer", ">=", start)
      .where("submissionDates.qcReviewer", "<=", end);

    const [transcriberDocRef, reviewerDocRef, qcReviewerDocRef] = await Promise.all([transcriberSnapshot.get(), reviewerSnapshot.get(), qcReviewerSnapshot.get()]);
    return mergeCSVData(transcriberDocRef.docs, reviewerDocRef.docs, qcReviewerDocRef.docs) as AudioFile[];
  }

  const ref = await db.collection(COLLECTIONS.AUDIO_FILES).get();
  const data = ref.docs.map((doc) => doc.data());
  return data as AudioFile[];
};

const getQuery = (roles: ROLES[], serverSideFilters: ServerSideFilters) => {
  const { columnKey, order } = serverSideFilters.sorter;
  // TODO: this could be expressed as where("isEnhancedSeg", "==", "true") with the right refactor -
  // allowing us to use the "array-contains" operator for the tags, without indexing any
  let lazySnapshot = db.collection(COLLECTIONS.AUDIO_FILES).where("pathTags", "array-contains", "enhanced_seg");
  let durationSortDirection: firebase.firestore.OrderByDirection = "desc";
  if (columnKey === "duration") {
    durationSortDirection = order === "ascend" ? "asc" : "desc";
  }

  if (serverSideFilters.creationDate) {
    const [start, end] = serverSideFilters.creationDate;
    lazySnapshot = lazySnapshot
      .orderBy("creationDate")
      .orderBy("duration", durationSortDirection)
      .where("creationDate", ">=", start)
      .where("creationDate", "<=", end);
  } else {
    lazySnapshot = lazySnapshot
      .orderBy("duration", durationSortDirection)
      .where("duration", ">=", MINIMUM_FILE_DURATION)
      .where("duration", "<=", serverSideFilters.fileDuration);
  }

  if (columnKey && columnKey !== "duration") {
    lazySnapshot = lazySnapshot.orderBy(columnKey as string, order === "ascend" ? "asc" : "desc");
  }

  if (serverSideFilters.filenames?.length) {
    const { filenames } = serverSideFilters;
    if (filenames.length === 1) {
      const [filename] = filenames;
      lazySnapshot = lazySnapshot.where("filename", "==", filename);
    } else {
      lazySnapshot = lazySnapshot.where("filename", "in", filenames);
    }
  }

  if (serverSideFilters.filters) {
    const { filename, status, transcriber, reviewer, qcReviewer, tags } = serverSideFilters?.filters;

    if (filename) {
      lazySnapshot = lazySnapshot.where("path", "==", filename[0]);
    }

    if (status) {
      lazySnapshot = lazySnapshot.where("status", "==", status[0]);
    }

    if (transcriber) {
      lazySnapshot = lazySnapshot.where("transcriber.email", "==", transcriber[0]);
    }

    if (reviewer) {
      lazySnapshot = lazySnapshot.where("reviewer.email", "==", reviewer[0]);
    }

    if (qcReviewer) {
      lazySnapshot = lazySnapshot.where("qcReviewer.email", "==", qcReviewer[0]);
    }

    if (tags) {
      const tag = `tags.${tags[0]}`;
      lazySnapshot = lazySnapshot.where(tag, "==", true);
    }
  }

  const uniqueRoles = new Set(roles);
  const isTranscriber = uniqueRoles.has(ROLES.TRANSCRIBER) && uniqueRoles.size === 1;

  if (isTranscriber) {
    const { NEW, TRANSCRIBE_IN_PROGRESS, WAITING_FOR_REVIEW } = AUDIOFILE_STATUS;
    lazySnapshot = lazySnapshot.where("status", "in", [NEW, TRANSCRIBE_IN_PROGRESS, WAITING_FOR_REVIEW]);
  }

  if (serverSideFilters?.pagination) {
    const { cursor } = serverSideFilters.pagination;
    lazySnapshot = lazySnapshot.startAfter(cursor);
  }

  lazySnapshot = lazySnapshot.limit(PAGE_SIZE);
  return lazySnapshot;
};

const getQueries = (roles: ROLES[], serverSideFilters: ServerSideFilters) => {
  let queries;

  if (serverSideFilters.filenames?.length! > 1) {
    const { filenames } = serverSideFilters;
    const uniqueRoles = new Set(roles);
    const isTranscriber = uniqueRoles.has(ROLES.TRANSCRIBER) && uniqueRoles.size === 1;
    let chunkSize = 10;
    if (isTranscriber) {
      chunkSize = 1;
    }

    const chunks = chunk(chunkSize, filenames);
    queries = chunks.map((chunk) => getQuery(roles, { ...serverSideFilters, filenames: chunk }));
  } else {
    queries = [getQuery(roles, serverSideFilters)];
  }

  return queries;
};

const getRawDocs = async (roles: ROLES[], serverSideFilters: ServerSideFilters, maximumFileDuration: number): Promise<FirebaseDocumentData[]> => {
  const queries = getQueries(roles, serverSideFilters);
  const pendingQueries = queries.map((query) => query.get({ source: "server" }));
  const data = await Promise.all(pendingQueries);
  return data.flatMap((d) => d.docs);
};

const update = async (id: string, payload: FileChangePayload): Promise<void> => {
  const fileRef = db.collection(COLLECTIONS.AUDIO_FILES).doc(id);
  return db.runTransaction(async (transaction) => {
    const { TRANSCRIBE_IN_PROGRESS, NEW } = AUDIOFILE_STATUS;
    const fileSnapshot = await transaction.get(fileRef);
    const file = fileSnapshot.data();

    if (file) {
      const shouldReject = payload.status === TRANSCRIBE_IN_PROGRESS && file.status !== NEW && file.transcriber;
      if (shouldReject) {
        throw new Error(`File ${file.filename} is already taken, please select a different file`);
      } else {
        const serializedPayload = {
          ...payload,
          id,
        };

        transaction.update(fileRef, serializedPayload);
      }
    }
  });
};

export const audioFiles = {
  update,
  getDataForCSV,
  getQueries,
  subscribeToFileChanges,
  getRawDocs,
};
